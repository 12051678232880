import { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

import logo from '../assets/logo_round.png';

type LayoutProps = {
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const doLogout = async (): Promise<void> => {
        if (logout) {
            logout();
            navigate('/login');
        }
    };

    return (
        <>
            <nav className="flex items-center justify-between flex-wrap bg-gray-300 py-4 px-32 uppercase">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img src={logo} className="h-10 mr-4" alt="CTSystems Admin" title="CTSystems Admin" />
                    <Link to="/" className="font-semibold text-ctsystems">
                        CT Systems Area Riservata
                    </Link>
                </div>
                <div className="flex-grow flex items-center w-auto">
                    <div className="flex-grow">
                        <Link to="/sections" className="inline-block mr-4">
                            Sezioni
                        </Link>
                        <Link to="/categories" className="inline-block mr-4">
                            Categorie
                        </Link>
                        <Link to="/products" className="inline-block mr-4">
                            Prodotti
                        </Link>
                        <Link to="/applications" className="inline-block mr-4">
                            Applicazioni
                        </Link>
                        <Link to="/event" className="inline-block mr-4">
                            Eventi
                        </Link>
                    </div>
                    <div>
                        <button className="inline-block px-4 py-2 leading-none rounded bg-ctsystems text-white" onClick={doLogout}>
                            LOGOUT
                        </button>
                    </div>
                </div>
            </nav>

            <main className="py-4 px-32">{props.children}</main>
        </>
    );
};
