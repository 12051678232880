import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useAuth } from '../contexts/auth';

import logo from '../assets/logo_round.png';

type LoginForm = {
    email: string;
    password: string;
};

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const { handleSubmit, register, formState } = useForm<LoginForm>({ mode: 'onChange' });

    const { errors } = formState;

    const onSubmit = async (values: LoginForm) => {
        try {
            if (login) {
                await login(values.email, values.password);
                navigate('/');
            }
        } catch (e) {
            toast.error('Si è verificato un errore durante il login');
        }
    };

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="w-1/3">
                <div className="p-4 flex justify-center">
                    <div className="bg-white w-32 h-32 rounded-full overflow-hidden">
                        <img src={logo} alt="CTSystems Login" title="CTSystems Login" />
                    </div>
                </div>
                <div className="p-4 bg-white rounded-lg">
                    <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            {...register('email', {
                                required: "L'email è obbligatoria",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "L'indirizzo email inserito non è valido"
                                }
                            })}
                            placeholder="Email"
                            type="email"
                            className="input focus:outline-none focus:shadow-outline"
                        />
                        {errors.email && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">{errors.email.message}</div>}

                        <input {...register('password', { required: true })} placeholder="Password" type="password" className="mt-4 input focus:outline-none focus:shadow-outline" />
                        {errors.password && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La password &egrave; obbligatoria</div>}

                        <button type="submit" className="button mt-8 w-full" disabled={!formState.isDirty || (formState.isDirty && !formState.isValid)}>
                            LOGIN
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
