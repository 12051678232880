import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../api';

import { Category } from '@ctsystems/common/category';
import { Section } from '@ctsystems/common/section';

import { Layout } from '../../components/_layout';

export const Categories: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const result = await api.get<Category[]>('/categories');
                setCategories(result.data);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchCategories();
    }, []);

    const deleteCategory = async (id?: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questa categoria?')) {
            try {
                await api.delete(`/category/${id}`);

                const categoryIndex = categories?.findIndex(s => s.id === id);
                if (categories && categoryIndex && categoryIndex > -1) {
                    const tmp = [...categories];
                    tmp.splice(categoryIndex, 1);
                    setCategories(tmp);
                }

                toast.success('Categoria eliminata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Categorie Prodotto</h1>

            <Link to="/categories/manage" className="button w-1/5 mt-4">
                Aggiungi categoria
            </Link>

            <table className="table-fixed w-full mt-8">
                <thead>
                    <tr>
                        <th className="px-4 py-2 text-left">Nome IT</th>
                        <th className="px-4 py-2 text-left">Nome EN</th>
                        <th className="px-4 py-2 text-left">Nome DE</th>
                        <th className="px-4 py-2 text-left">Sezione</th>
                        <th className="w-1/5 px-4 py-2 text-left">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {categories &&
                        categories.map(c => (
                            <tr key={c.id}>
                                <td className="border-b px-4 py-2">{c.translations.find(t => t.lang === 'it')?.name}</td>
                                <td className="border-b px-4 py-2">{c.translations.find(t => t.lang === 'en')?.name}</td>
                                <td className="border-b px-4 py-2">{c.translations.find(t => t.lang === 'de')?.name}</td>
                                <td className="border-b px-4 py-2">
                                    <Link to={`/sections/${(c.section as Section).id}`}>{(c.section as Section).translations.find(t => t.lang === 'it')?.name}</Link>
                                </td>
                                <td className="border-b px-4 py-2">
                                    <div className="flex">
                                        <Link to={`/categories/manage/${c.id}`} className="button mr-2">
                                            Modifica
                                        </Link>
                                        <button className="button bg-red-600" onClick={() => deleteCategory(c.id)}>
                                            Elimina
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Layout>
    );
};
