import { ReactElement } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RequireAuth } from './RequireAuth';

import { Login } from './pages/login';
import { Home } from './pages/home';
import { Sections } from './pages/sections';
import { ManageSection } from './pages/sections/manage';
import { Categories } from './pages/categories';
import { ManageCategory } from './pages/categories/manage';
import { Products } from './pages/products';
import { ManageProduct } from './pages/products/manage';
import { Applications } from './pages/applications';
import { ManageApplication } from './pages/applications/manage';
import { ManageEvent } from './pages/event';

import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

export default function App(): ReactElement {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route path="/" element={<Home />} />

                        <Route path="/sections">
                            <Route index element={<Sections />} />
                            <Route path="manage">
                                <Route index element={<ManageSection />} />
                                <Route path=":sid" element={<ManageSection />} />
                            </Route>
                        </Route>

                        <Route path="/categories">
                            <Route index element={<Categories />} />
                            <Route path="manage">
                                <Route index element={<ManageCategory />} />
                                <Route path=":cid" element={<ManageCategory />} />
                            </Route>
                        </Route>

                        <Route path="/products">
                            <Route index element={<Products />} />
                            <Route path="manage">
                                <Route index element={<ManageProduct />} />
                                <Route path=":pid" element={<ManageProduct />} />
                            </Route>
                        </Route>

                        <Route path="/applications">
                            <Route index element={<Applications />} />
                            <Route path="manage">
                                <Route index element={<ManageApplication />} />
                                <Route path=":aid" element={<ManageApplication />} />
                            </Route>
                        </Route>

                        <Route path="/event" element={<ManageEvent />} />
                    </Route>
                </Routes>
            </Router>

            <ToastContainer
                key="ToastContainer"
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                closeButton={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="colored"
                pauseOnHover={false}
            />
        </>
    );
}
