import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../api';

import { Layout } from '../../components/_layout';

import { Application } from '@ctsystems/common/application';

export const Applications: React.FC = () => {
    const [applications, setApplications] = useState<Application[]>();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const result = await api.get<Application[]>('/applications');
                setApplications(result.data);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchProducts();
    }, []);

    const deleteApplication = async (id?: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questa applicazione?')) {
            try {
                await api.delete(`/application/${id}`);

                const applicationIndex = applications?.findIndex(a => a.id === id);
                if (applications && applicationIndex && applicationIndex > -1) {
                    const tmp = [...applications];
                    tmp.splice(applicationIndex, 1);
                    setApplications(tmp);
                }

                toast.success('Applicazione eliminata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Applicazioni</h1>

            <Link to="/applications/manage" className="button w-1/5">
                Aggiungi applicazione
            </Link>

            <table className="table-fixed w-full mt-8">
                <thead>
                    <tr>
                        <th className="px-4 py-2 text-left">Nome IT</th>
                        <th className="px-4 py-2 text-left">Nome EN</th>
                        <th className="px-4 py-2 text-left">Nome DE</th>
                        <th className="w-1/5 px-4 py-2 text-left">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {applications &&
                        applications.map(a => (
                            <tr key={a.id}>
                                <td className="border-b px-4 py-2">{a.translations.find(t => t.lang === 'it')?.name}</td>
                                <td className="border-b px-4 py-2">{a.translations.find(t => t.lang === 'en')?.name}</td>
                                <td className="border-b px-4 py-2">{a.translations.find(t => t.lang === 'de')?.name}</td>
                                <td className="border-b px-4 py-2">
                                    <div className="flex">
                                        <Link to={`/applications/manage/${a.id}`} className="button mr-2">
                                            Modifica
                                        </Link>
                                        <button className="button bg-red-600" onClick={() => deleteApplication(a.id)}>
                                            Elimina
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Layout>
    );
};
