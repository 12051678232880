import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '../../api';

import { Application } from '@ctsystems/common/application';

import { Layout } from '../../components/_layout';

import WYSIWYGEditor from '../../components/wysiwyg';

type Form = {
    nameIT: string;
    nameEN: string;
    nameDE: string;
    descriptionIT: string;
    descriptionEN: string;
    descriptionDE: string;
};

export const ManageApplication: React.FC = () => {
    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<Form>();

    const { aid } = useParams<{ aid: string }>();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const result = await api.get<Application>(`/application/${aid}`);
                setValue('nameIT', result.data.translations.find(t => t.lang === 'it')?.name ?? '');
                setValue('nameEN', result.data.translations.find(t => t.lang === 'en')?.name ?? '');
                setValue('nameDE', result.data.translations.find(t => t.lang === 'de')?.name ?? '');
                setValue('descriptionIT', result.data.translations.find(t => t.lang === 'it')?.description ?? '');
                setValue('descriptionEN', result.data.translations.find(t => t.lang === 'en')?.description ?? '');
                setValue('descriptionDE', result.data.translations.find(t => t.lang === 'de')?.description ?? '');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        if (aid) {
            fetchProduct();
        }
    }, [aid, setValue]);

    const onSubmit = async (values: Form) => {
        try {
            const parsed = {
                translations: [
                    { lang: 'it', name: values.nameIT, description: values.descriptionIT },
                    { lang: 'en', name: values.nameEN, description: values.descriptionEN },
                    { lang: 'de', name: values.nameDE, description: values.descriptionDE }
                ]
            };

            const result = await api.post<{ id: string }>('/application', { id: aid, application: parsed });

            toast.success('Applicazione salvata con successo');

            if (!aid) {
                navigate(`/applications/manage/${result.data.id}`, { replace: true });
            }
        } catch (e) {
            toast.error('Si è verificato un errore durante il salvataggio');
        }
    };

    return (
        <Layout>
            <h1>Dettaglio applicazione</h1>

            <form className="mt-8 grid grid-cols-3 gap-8" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label className="block font-bold mb-1" htmlFor="nameIT">
                        Applicazione in italiano
                    </label>
                    <input {...register('nameIT', { required: true })} placeholder="Applicazione" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome dell&apos;applicazione è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionIT">
                        Descrizione
                    </label>
                    <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionIT" control={control} rules={{ required: true }} />
                    {errors.descriptionIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La dell&apos;applicazione è obbligatoria</div>}
                </div>

                <div>
                    <label className="block font-bold mb-1" htmlFor="nameEN">
                        Applicazione in inglese
                    </label>
                    <input {...register('nameEN', { required: true })} placeholder="Application" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome dell&apos;applicazione è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionEN">
                        Descrizione
                    </label>
                    <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionEN" control={control} rules={{ required: true }} />
                    {errors.descriptionEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La dell&apos;applicazione è obbligatoria</div>}
                </div>

                <div>
                    <label className="block font-bold mb-1" htmlFor="nameDE">
                        Applicazione in tedesco
                    </label>
                    <input {...register('nameDE', { required: true })} placeholder="Anwendung" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome dell&apos;applicazione è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionDE">
                        Descrizione
                    </label>
                    <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionDE" control={control} rules={{ required: true }} />
                    {errors.descriptionDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La dell&apos;applicazione è obbligatoria</div>}
                </div>

                <div className="col-span-3">
                    <button type="submit" className="button w-full">
                        SALVA
                    </button>
                </div>
            </form>
        </Layout>
    );
};
