import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type EditorProps = {
    value?: string;
    onChange?(pippo: unknown): unknown;
};

const WYSIWYGEditor: React.FC<EditorProps> = (props: EditorProps) => {
    const textChanged = (value: string) => {
        if (props.onChange) {
            return props.onChange(value);
        }
    };

    return <ReactQuill theme="snow" placeholder="Inserisci qui il testo" value={props.value} onChange={textChanged} />;
};

export default WYSIWYGEditor;
