import { useEffect, useState, ReactNode, ReactElement, useContext, createContext } from 'react';
import api from '../api';

type ContextProps = {
    isAuthenticated?: boolean;
    loading?: boolean;
    login?(email: string, password: string): Promise<void>;
    logout?(): void;
    children?: ReactNode;
};

export const AuthContext = createContext<Partial<ContextProps>>({});
export const useAuth = (): Partial<ContextProps> => useContext(AuthContext);

export const AuthProvider: React.FC<ContextProps> = ({ children }: ContextProps): ReactElement => {
    const TOKEN_KEY = 'token';

    const [token, setToken] = useState<string>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const sessionToken = sessionStorage.getItem(TOKEN_KEY);
        if (sessionToken) {
            setToken(sessionToken);
        }

        setLoading(false);
    }, []);

    const login = async (email: string, password: string) => {
        const result = await api.post<{ token: string }>('/auth/login', { email, password });
        const { token } = result.data;
        sessionStorage.setItem(TOKEN_KEY, token);
        setToken(token);
    };

    const logout = () => {
        sessionStorage.clear();
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: !!token,
                loading,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
