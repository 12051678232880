import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../api';

import { Layout } from '../../components/_layout';

import { Section } from '@ctsystems/common/section';

export const Sections: React.FC = () => {
    const [sections, setSections] = useState<Section[]>();

    useEffect(() => {
        const fetchSections = async () => {
            try {
                const result = await api.get<Section[]>('/sections');
                setSections(result.data);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchSections();
    }, []);

    const deleteSection = async (id?: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo sezione?')) {
            try {
                await api.delete(`/section/${id}`);

                const sectionIndex = sections?.findIndex(s => s.id === id);
                if (sections && sectionIndex && sectionIndex > -1) {
                    const tmp = [...sections];
                    tmp.splice(sectionIndex, 1);
                    setSections(tmp);
                }

                toast.success('Sezione eliminata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Sezioni prodotto</h1>

            <Link to="/sections/manage" className="button w-1/5 mt-4">
                Aggiungi sezione
            </Link>

            <table className="table-fixed w-full mt-8">
                <thead>
                    <tr>
                        <th className="px-4 py-2 text-left">Nome IT</th>
                        <th className="px-4 py-2 text-left">Nome EN</th>
                        <th className="px-4 py-2 text-left">Nome DE</th>
                        <th className="w-1/5 px-4 py-2 text-left">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {sections &&
                        sections.map(s => (
                            <tr key={s.id}>
                                <td className="border-b px-4 py-2">{s.translations.find(t => t.lang === 'it')?.name}</td>
                                <td className="border-b px-4 py-2">{s.translations.find(t => t.lang === 'en')?.name}</td>
                                <td className="border-b px-4 py-2">{s.translations.find(t => t.lang === 'de')?.name}</td>
                                <td className="border-b px-4 py-2">
                                    <div className="flex">
                                        <Link to={`/sections/manage/${s.id}`} className="button mr-2">
                                            Modifica
                                        </Link>
                                        <button className="button bg-red-600" onClick={() => deleteSection(s.id)}>
                                            Elimina
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Layout>
    );
};
