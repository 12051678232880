import { useEffect, useState, ChangeEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '../../api';

import { Category } from '@ctsystems/common/category';
import { Section } from '@ctsystems/common/section';

import { Layout } from '../../components/_layout';

import WYSIWYGEditor from '../../components/wysiwyg';

type Form = {
    section: string;
    nameIT: string;
    descriptionIT: string;
    nameEN: string;
    descriptionEN: string;
    nameDE: string;
    descriptionDE: string;
};

export const ManageCategory: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<Form>();

    const { cid } = useParams<{ cid: string }>();

    const [sections, setSections] = useState<Section[]>();
    const [image, setImage] = useState<string>();

    useEffect(() => {
        const fetchSections = async () => {
            try {
                const result = await api.get<Section[]>('/sections');
                setSections(result.data);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchSections();
    }, []);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const result = await api.get<Category>(`/category/${cid}`);

                setValue('section', (result.data.section as Section).id ?? '');
                setValue('nameIT', result.data.translations.find(t => t.lang === 'it')?.name ?? '');
                setValue('descriptionIT', result.data.translations.find(t => t.lang === 'it')?.description ?? '');
                setValue('nameEN', result.data.translations.find(t => t.lang === 'en')?.name ?? '');
                setValue('descriptionEN', result.data.translations.find(t => t.lang === 'en')?.description ?? '');
                setValue('nameDE', result.data.translations.find(t => t.lang === 'de')?.name ?? '');
                setValue('descriptionDE', result.data.translations.find(t => t.lang === 'de')?.description ?? '');

                setImage(result.data.imageUrl);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        if (sections && sections.length > 0 && cid) {
            fetchCategory();
        }
    }, [sections, cid, setValue]);

    const onSubmit = async (values: Form) => {
        try {
            const parsed = {
                section: values.section,
                translations: [
                    { lang: 'it', name: values.nameIT, description: values.descriptionIT },
                    { lang: 'en', name: values.nameEN, description: values.descriptionEN },
                    { lang: 'de', name: values.nameDE, description: values.descriptionDE }
                ]
            };

            const result = await api.post<{ id: string }>('/category/manage', { id: cid, category: parsed });

            toast.success('Categoria salvata con successo');

            if (!cid) {
                navigate(`/categories/manage/${result.data.id}`, { replace: true });
            }
        } catch (e) {
            toast.error('Si è verificato un errore durante il salvataggio');
        }
    };

    const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const data = new FormData();
            data.append('id', cid as string);
            data.append('image', file, file.name);

            try {
                const result = await api.post<string>('/category/image', data);
                setImage(result.data);

                toast.success('Immagine caricata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    const deleteImage = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questa immagine?')) {
            try {
                await api.delete(`/category/image/${cid}`);

                setImage(undefined);

                toast.success('Immagine eliminata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Dettaglio categoria</h1>

            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                <label className="block font-bold mb-1" htmlFor="section">
                    Sezione
                </label>
                <div className="inline-block relative w-full">
                    <select className="block appearance-none w-full bg-white border border-gray-400 p-4 pr-8 rounded" {...register('section', { required: true })}>
                        {sections &&
                            sections.map(s => (
                                <option key={s.id} value={s.id}>
                                    {s.translations.find(t => t.lang === 'it')?.name}
                                </option>
                            ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
                {errors.section && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La sezione è obbligatoria</div>}

                <div className="mt-4 grid grid-cols-3 gap-8">
                    <div>
                        <label className="block font-bold mb-1" htmlFor="nameIT">
                            Categoria in italiano
                        </label>
                        <input {...register('nameIT', { required: true })} placeholder="Categoria" type="text" className="input focus:outline-none focus:shadow-outline" />
                        {errors.nameIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome della categoria è obbligatorio</div>}

                        <label className="block font-bold mb-1 mt-4" htmlFor="descriptionIT">
                            Descrizione
                        </label>
                        <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionIT" control={control} rules={{ required: true }} />
                        {errors.descriptionIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione della categoria è obbligatoria</div>}
                    </div>

                    <div>
                        <label className="block font-bold mb-1" htmlFor="nameEN">
                            Categoria in inglese
                        </label>
                        <input {...register('nameEN', { required: true })} placeholder="Category" type="text" className="input focus:outline-none focus:shadow-outline" />
                        {errors.nameEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome della categoria è obbligatorio</div>}

                        <label className="block font-bold mb-1 mt-4" htmlFor="descriptionEN">
                            Descrizione
                        </label>
                        <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionEN" control={control} rules={{ required: true }} />
                        {errors.descriptionEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione della categoria è obbligatoria</div>}
                    </div>

                    <div>
                        <label className="block font-bold mb-1" htmlFor="nameDE">
                            Categoria in tedesco
                        </label>
                        <input {...register('nameDE', { required: true })} placeholder="Category" type="text" className="input focus:outline-none focus:shadow-outline" />
                        {errors.nameDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome della categoria è obbligatorio</div>}

                        <label className="block font-bold mb-1 mt-4" htmlFor="descriptionDE">
                            Descrizione
                        </label>
                        <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionDE" control={control} rules={{ required: true }} />
                        {errors.descriptionDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione della categoria è obbligatoria</div>}
                    </div>
                </div>

                <button type="submit" className="button mt-8 w-full">
                    SALVA
                </button>
            </form>

            {cid && (
                <>
                    {!image ? (
                        <div className="mt-8">
                            <h3>Carica immagine per la categoria (facoltativo)</h3>
                            <div className="flex justify-center mt-4">
                                <label className="w-64 flex flex-col items-center px-4 py-6 bg-ctsystems text-white rounded-lg uppercase cursor-pointer">
                                    <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>
                                    <span className="mt-2">Seleziona immagine</span>
                                    <input type="file" className="hidden" accept=".jpg,.png,.jpeg" onChange={uploadImage} />
                                </label>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h4 className="mt-8">Immagine presente</h4>
                            <div className="w-64 mt-4 flex flex-col items-center bg-gray-200">
                                <img className="h-48 mt-4" src={image} alt="" />
                                <button className="mt-1 button w-full bg-red-600" onClick={() => deleteImage()}>
                                    Elimina
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
        </Layout>
    );
};
