import { useEffect, useState, ChangeEvent } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';

import api from '../api';

import { Event } from '@ctsystems/common/event';

import { Layout } from '../components/_layout';
import WYSIWYGEditor from '../components/wysiwyg';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('it', it);

type Form = {
    date: Date;
    nameIT: string;
    nameEN: string;
    nameDE: string;
    descriptionIT: string;
    descriptionEN: string;
    descriptionDE: string;
};

export const ManageEvent: React.FC = () => {
    const {
        handleSubmit,
        register,
        setValue,
        reset,
        control,
        formState: { errors }
    } = useForm<Form>();

    const [exists, setExists] = useState(false);
    const [image, setImage] = useState<string>();

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const result = await api.get<Event>('/event');

                if (result.data) {
                    setValue('date', new Date(result.data.date));
                    setValue('nameIT', result.data.translations.find(t => t.lang === 'it')?.name ?? '');
                    setValue('nameEN', result.data.translations.find(t => t.lang === 'en')?.name ?? '');
                    setValue('nameDE', result.data.translations.find(t => t.lang === 'de')?.name ?? '');
                    setValue('descriptionIT', result.data.translations.find(t => t.lang === 'it')?.description ?? '');
                    setValue('descriptionEN', result.data.translations.find(t => t.lang === 'en')?.description ?? '');
                    setValue('descriptionDE', result.data.translations.find(t => t.lang === 'de')?.description ?? '');

                    setImage(result.data.imageUrl);

                    setExists(true);
                }
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchEvent();
    }, [setValue]);

    const onSubmit = async (values: Form) => {
        try {
            const parsed = {
                date: values.date,
                translations: [
                    { lang: 'it', name: values.nameIT, description: values.descriptionIT },
                    { lang: 'en', name: values.nameEN, description: values.descriptionEN },
                    { lang: 'de', name: values.nameDE, description: values.descriptionDE }
                ]
            };

            await api.post('/event', parsed);

            setExists(true);

            toast.success('Evento salvato con successo');
        } catch (e) {
            toast.error('Si è verificato un errore durante il salvataggio');
        }
    };

    const deleteEvent = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questo evento?')) {
            try {
                await api.delete('/event');

                setExists(false);

                reset();

                toast.success('Evento eliminato con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const data = new FormData();
            data.append('image', file, file.name);

            try {
                const result = await api.post<string>('/event/image', data);

                setImage(result.data);

                toast.success('Immagine caricata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    const deleteImage = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questa immagine?')) {
            try {
                await api.delete('/event/image');

                setImage(undefined);

                toast.success('Immagine eliminata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Dettaglio evento</h1>

            <form className="mt-8 grid grid-cols-3 gap-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-span-3">
                    <label className="block font-bold mb-1" htmlFor="date">
                        Data Evento
                    </label>
                    <Controller
                        control={control}
                        name="date"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <DatePicker
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                selected={field.value}
                                customInput={<input className="input focus:outline-none focus:shadow-outline" />}
                                locale="it"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Data"
                            />
                        )}
                    />
                    {errors.date && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La data dell&apos;evento è obbligatoria</div>}
                </div>
                <div>
                    <label className="block font-bold mb-1" htmlFor="nameIT">
                        Evento in italiano
                    </label>
                    <input {...register('nameIT', { required: true })} placeholder="Evento" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome dell&apos;evento è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionIT">
                        Descrizione
                    </label>
                    <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionIT" control={control} defaultValue="" rules={{ required: true }} />
                    {errors.descriptionIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione dell&apos;evento è obbligatoria</div>}
                </div>

                <div>
                    <label className="block font-bold mb-1" htmlFor="nameEN">
                        Evento in inglese
                    </label>
                    <input {...register('nameEN', { required: true })} placeholder="Event" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome dell&apos;evento è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionEN">
                        Descrizione
                    </label>
                    <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionEN" control={control} defaultValue="" rules={{ required: true }} />
                    {errors.descriptionEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione dell&apos;evento è obbligatoria</div>}
                </div>

                <div>
                    <label className="block font-bold mb-1" htmlFor="nameDE">
                        Evento in tedesco
                    </label>
                    <input {...register('nameDE', { required: true })} placeholder="Veranstaltung" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome dell&apos;evento è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionDE">
                        Descrizione
                    </label>
                    <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionDE" control={control} defaultValue="" rules={{ required: true }} />
                    {errors.descriptionDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione dell&apos;evento è obbligatoria</div>}
                </div>

                <div className="col-span-3">
                    <button type="submit" className="button w-full">
                        SALVA
                    </button>

                    {exists && (
                        <button type="button" className="mt-2 button bg-red-600 w-full" onClick={() => deleteEvent()}>
                            ELIMINA
                        </button>
                    )}
                </div>
            </form>

            {exists && (
                <>
                    {!image ? (
                        <div className="mt-8">
                            <h3>Carica immagine per l&apos;evento (facoltativo)</h3>
                            <div className="flex justify-center mt-4">
                                <label className="w-64 flex flex-col items-center px-4 py-6 bg-ctsystems text-white rounded-lg uppercase cursor-pointer">
                                    <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>
                                    <span className="mt-2">Seleziona immagine</span>
                                    <input type="file" className="hidden" accept=".jpg,.png,.jpeg" onChange={uploadImage} />
                                </label>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h4 className="mt-8">Immagine presente</h4>
                            <img className="h-48 mt-4" src={image} alt="" />
                            <button className="mt-1 button bg-red-600" onClick={() => deleteImage()}>
                                Elimina
                            </button>
                        </>
                    )}
                </>
            )}
        </Layout>
    );
};
