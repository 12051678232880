import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import { AuthProvider } from './contexts/auth';
import App from './App';

const root = createRoot(document.getElementById('root') as Element);
root.render(
    <StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </StrictMode>
);
