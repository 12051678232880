import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../api';

import { Layout } from '../../components/_layout';

import { Product } from '@ctsystems/common/product';
import { Category } from '@ctsystems/common/category';

export const Products: React.FC = () => {
    const [products, setProducts] = useState<Product[]>();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const result = await api.get<Product[]>('/products');
                setProducts(result.data);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchProducts();
    }, []);

    const deleteProduct = async (id?: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo prodotto?')) {
            try {
                await api.delete(`/product/${id}`);

                const productIndex = products?.findIndex(s => s.id === id);
                if (products && productIndex && productIndex > -1) {
                    const tmp = [...products];
                    tmp.splice(productIndex, 1);
                    setProducts(tmp);
                }

                toast.success('Prodotto eliminato con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Prodotti</h1>

            <Link to="/products/manage" className="button w-1/5 mt-4">
                Aggiungi prodotto
            </Link>

            <table className="table-fixed w-full mt-8">
                <thead>
                    <tr>
                        <th className="px-4 py-2 text-left">Prodotto</th>
                        <th className="px-4 py-2 text-left">Categoria</th>
                        <th className="w-1/5 px-4 py-2 text-left">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {products &&
                        products.map(p => (
                            <tr key={p.id}>
                                <td className="border-b px-4 py-2">{p.name}</td>
                                <td className="border-b px-4 py-2">
                                    <Link to={`/categories/${(p.category as Category).id}`}>{(p.category as Category).translations.find(t => t.lang === 'it')?.name}</Link>
                                </td>
                                <td className="border-b px-4 py-2">
                                    <div className="flex">
                                        <Link to={`/products/manage/${p.id}`} className="button mr-2">
                                            Modifica
                                        </Link>
                                        <button className="button bg-red-600" onClick={() => deleteProduct(p.id)}>
                                            Elimina
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Layout>
    );
};
