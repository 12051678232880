import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '../../api';

import { Section } from '@ctsystems/common/section';

import { Layout } from '../../components/_layout';

type Form = {
    nameIT: string;
    descriptionIT: string;
    nameEN: string;
    descriptionEN: string;
    nameDE: string;
    descriptionDE: string;
};

export const ManageSection: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm<Form>();

    const { sid } = useParams<{ sid: string }>();

    useEffect(() => {
        const fetchSection = async () => {
            try {
                const result = await api.get<Section>(`/section/${sid}`);
                const data = result.data;

                setValue('nameIT', data.translations.find(t => t.lang === 'it')?.name ?? '');
                setValue('descriptionIT', data.translations.find(t => t.lang === 'it')?.description ?? '');
                setValue('nameEN', data.translations.find(t => t.lang === 'en')?.name ?? '');
                setValue('descriptionEN', data.translations.find(t => t.lang === 'en')?.description ?? '');
                setValue('nameDE', data.translations.find(t => t.lang === 'de')?.name ?? '');
                setValue('descriptionDE', data.translations.find(t => t.lang === 'de')?.description ?? '');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        if (sid) {
            fetchSection();
        }
    }, [sid, setValue]);

    const onSubmit = async (values: Form) => {
        try {
            const parsed = {
                translations: [
                    { lang: 'it', name: values.nameIT, description: values.descriptionIT },
                    { lang: 'en', name: values.nameEN, description: values.descriptionEN },
                    { lang: 'de', name: values.nameDE, description: values.descriptionDE }
                ]
            };

            const result = await api.post<{ id: string }>('/section/manage', { id: sid, section: parsed });

            if (!sid) {
                navigate(`/sections/manage/${result.data.id}`, { replace: true });
            }

            toast.success('Sezione salvata con successo');
        } catch (e) {
            toast.error('Si è verificato un errore durante il salvataggio');
        }
    };

    return (
        <Layout>
            <h1>Dettaglio sezione</h1>
            <form className="mt-8 grid grid-cols-3 gap-8" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label className="block font-bold mb-1" htmlFor="nameIT">
                        Sezione in italiano
                    </label>
                    <input {...register('nameIT', { required: true })} placeholder="Sezione" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome della sezione è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionIT">
                        Descrizione
                    </label>
                    <textarea {...register('descriptionIT', { required: true })} placeholder="Descrizione" className="input focus:outline-none focus:shadow-outline" />
                    {errors.descriptionIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione della sezione è obbligatoria</div>}
                </div>

                <div>
                    <label className="block font-bold mb-1" htmlFor="nameEN">
                        Sezione in inglese
                    </label>
                    <input {...register('nameEN', { required: true })} placeholder="Section" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome della sezione è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionEN">
                        Descrizione
                    </label>
                    <textarea {...register('descriptionEN', { required: true })} placeholder="Description" className="input focus:outline-none focus:shadow-outline" />
                    {errors.descriptionEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione della sezione è obbligatoria</div>}
                </div>

                <div>
                    <label className="block font-bold mb-1" htmlFor="nameDE">
                        Sezione in tedesco
                    </label>
                    <input {...register('nameDE', { required: true })} placeholder="Abschnitt" type="text" className="input focus:outline-none focus:shadow-outline" />
                    {errors.nameDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome della sezione è obbligatorio</div>}

                    <label className="block font-bold mb-1 mt-4" htmlFor="descriptionDE">
                        Descrizione
                    </label>
                    <textarea {...register('descriptionDE', { required: true })} placeholder="Beschreibung" className="input focus:outline-none focus:shadow-outline" />
                    {errors.descriptionDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione della sezione è obbligatoria</div>}
                </div>

                <div className="col-span-3">
                    <button type="submit" className="button mt-8 w-full">
                        SALVA
                    </button>
                </div>
            </form>
        </Layout>
    );
};
