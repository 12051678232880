import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

class Api {
    private api: AxiosInstance;
    private TOKEN_KEY = 'token';

    private config: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        paramsSerializer: {
            encode: params => qs.stringify(params, { indices: false })
        }
    };

    public constructor() {
        this.api = axios.create(this.config);

        this.api.interceptors.request.use((config) => {
            const token = sessionStorage.getItem(this.TOKEN_KEY);
            if (token && config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        });

        this.api.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            error => {
                if (error.response.config.url !== '/auth/login' && error.response.status === 401) {
                    window.location.pathname = '/login';
                }

                return Promise.reject(error);
            }
        );
    }

    public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.get(url, config);
    }

    public post<T, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
        return this.api.post(url, data, config);
    }

    public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.delete(url, config);
    }
}

const api = new Api();
export default api;
