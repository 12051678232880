import { useEffect, useState, ChangeEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '../../api';

import { Category } from '@ctsystems/common/category';
import { Product } from '@ctsystems/common/product';
import { CTFile } from '@ctsystems/common/file';

import { Layout } from '../../components/_layout';

import WYSIWYGEditor from '../../components/wysiwyg';

import { ReactComponent as PDF } from '../../assets/PDF.svg';

type Form = {
    category: string;
    name: string;
    descriptionIT: string;
    descriptionEN: string;
    descriptionDE: string;
};

export const ManageProduct: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<Form>();

    const { pid } = useParams<{ pid: string }>();

    const [categories, setCategories] = useState<Category[]>();
    const [images, setImages] = useState<CTFile[]>();
    const [attachments, setAttachments] = useState<CTFile[]>();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const result = await api.get<Category[]>('/categories');
                setCategories(result.data);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const result = await api.get<Product>(`/product/${pid}`);

                setValue('category', (result.data.category as Category).id ?? '');
                setValue('name', result.data.name);
                setValue('descriptionIT', result.data.translations.find(t => t.lang === 'it')?.description ?? '');
                setValue('descriptionEN', result.data.translations.find(t => t.lang === 'en')?.description ?? '');
                setValue('descriptionDE', result.data.translations.find(t => t.lang === 'de')?.description ?? '');

                setImages(result.data.images);

                setAttachments(result.data.attachments);
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        };

        if (categories && categories.length > 0 && pid) {
            fetchProduct();
        }
    }, [categories, pid, setValue]);

    const onSubmit = async (values: Form) => {
        try {
            const parsed = {
                category: values.category,
                name: values.name,
                translations: [
                    { lang: 'it', description: values.descriptionIT },
                    { lang: 'en', description: values.descriptionEN },
                    { lang: 'de', description: values.descriptionDE }
                ]
            };

            const result = await api.post<{ id: string }>('/product/manage', { id: pid, product: parsed });

            if (!pid) {
                navigate(`/products/manage/${result.data.id}`, { replace: true });
            }

            toast.success('Prodotto salvato con successo');
        } catch (e) {
            toast.error('Si è verificato un errore durante il salvataggio');
        }
    };

    const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const data = new FormData();
            data.append('id', pid as string);
            data.append('image', file, file.name);

            try {
                const result = await api.post<CTFile>('/product/image', data);

                const tmp = (images && [...images]) || [];
                tmp.push(result.data);
                setImages(tmp);

                toast.success('Immagine caricata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    const deleteImage = async (id?: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questa immagine?')) {
            try {
                await api.delete(`/product/image/${pid}/${id}`);

                const imageIndex = images?.findIndex(i => i.id === id);
                if (images && imageIndex !== undefined && imageIndex > -1) {
                    const tmp = [...images];
                    tmp.splice(imageIndex, 1);
                    setImages(tmp);
                }

                toast.success('Immagine eliminata con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    const uploadAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const data = new FormData();
            data.append('id', pid as string);
            data.append('attachment', file, file.name);

            try {
                const result = await api.post<CTFile>('/product/attachment', data);

                const tmp = (attachments && [...attachments]) || [];
                tmp.push(result.data);
                setAttachments(tmp);

                toast.success('Allegato caricato con successo');
            } catch (e) {
                if (e instanceof Error) {
                    toast.error(e.message);
                }
            }
        }
    };

    const deleteAttachment = async (id?: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo allegato?')) {
            try {
                await api.delete(`/product/attachment/${pid}/${id}`);

                const attachmentIndex = attachments?.findIndex(a => a.id === id);
                if (attachments && attachmentIndex !== undefined && attachmentIndex > -1) {
                    const tmp = [...attachments];
                    tmp.splice(attachmentIndex, 1);
                    setAttachments(tmp);
                }

                toast.success('Allegato eliminato con successo');
            } catch (e) {
                if (e instanceof Error) {
                    if (e instanceof Error) {
                        toast.error(e.message);
                    }
                }
            }
        }
    };

    return (
        <Layout>
            <h1>Dettaglio prodotto</h1>

            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                <label className="block font-bold mb-1" htmlFor="category">
                    Sezione
                </label>
                <div className="inline-block relative w-full">
                    <select className="block appearance-none w-full bg-white border border-gray-400 p-4 pr-8 rounded" {...register('category', { required: true })}>
                        {categories &&
                            categories.map(c => (
                                <option key={c.id} value={c.id}>
                                    {c.translations.find(t => t.lang === 'it')?.name}
                                </option>
                            ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
                {errors.category && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La categoria è obbligatoria</div>}

                <label className="mt-4 block font-bold mb-1" htmlFor="name">
                    Nome prodotto
                </label>
                <input {...register('name', { required: true })} placeholder="Prodotto" type="text" className="input focus:outline-none focus:shadow-outline" />
                {errors.name && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">Il nome del prodotto è obbligatorio</div>}

                <div className="mt-4 grid grid-cols-3 gap-8">
                    <div>
                        <label className="block font-bold mb-1 mt-4" htmlFor="descriptionIT">
                            Descrizione italiana
                        </label>
                        <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionIT" control={control} rules={{ required: true }} />
                        {errors.descriptionIT && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione del prodotto è obbligatoria</div>}
                    </div>

                    <div>
                        <label className="block font-bold mb-1 mt-4" htmlFor="descriptionEN">
                            Descrizione inglese
                        </label>
                        <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionEN" control={control} rules={{ required: true }} />
                        {errors.descriptionEN && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione del prodotto è obbligatoria</div>}
                    </div>

                    <div>
                        <label className="block font-bold mb-1 mt-4" htmlFor="descriptionDE">
                            Descrizione tedesca
                        </label>
                        <Controller render={({ field }) => <WYSIWYGEditor {...field} />} name="descriptionDE" control={control} rules={{ required: true }} />
                        {errors.descriptionDE && <div className="bg-red-600 px-2 py-1 text-white text-sm font-bold italic">La descrizione del prodotto è obbligatoria</div>}
                    </div>
                </div>

                <button type="submit" className="button mt-8 w-full">
                    SALVA
                </button>
            </form>

            {pid && (
                <div className="mt-8 grid grid-cols-2 gap-8">
                    <div>
                        <h3>Carica immagine per il prodotto (facoltativo)</h3>
                        <div className="flex justify-center mt-4">
                            <label className="w-64 flex flex-col items-center px-4 py-6 bg-ctsystems text-white rounded-lg uppercase cursor-pointer">
                                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <span className="mt-2">Seleziona immagine</span>
                                <input type="file" className="hidden" accept=".jpg,.png,.jpeg" onChange={uploadImage} />
                            </label>
                        </div>

                        {images && images.length > 0 && (
                            <>
                                <h4 className="mt-8">Immagini presenti</h4>
                                <div className="mt-4 flex">
                                    {images.map(i => (
                                        <div key={i.id} className="w-64 flex flex-col items-center mr-2 pt-2 bg-gray-200">
                                            <div>
                                                <img className="h-48" src={i.url} alt="" />
                                            </div>
                                            <button className="mt-1 button  w-full bg-red-600" onClick={() => deleteImage(i.id)}>
                                                Elimina
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        <h3>Carica PDF per il prodotto (facoltativo)</h3>
                        <div className="flex justify-center mt-4">
                            <label className="w-64 flex flex-col items-center px-4 py-6 bg-red-400 text-white rounded-lg uppercase cursor-pointer">
                                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <span className="mt-2">Seleziona PDF</span>
                                <input type="file" className="hidden" accept=".pdf" onChange={uploadAttachment} />
                            </label>
                        </div>

                        {attachments && attachments.length > 0 && (
                            <>
                                <h4 className="mt-8">PDF presenti</h4>
                                <div className="mt-4 flex bg">
                                    {attachments.map(a => (
                                        <div key={a.id} className="w-64 flex flex-col items-center mr-2 pt-2 bg-gray-200">
                                            <a href={a.url}>
                                                <PDF className="h-48" />
                                            </a>
                                            <b>{a.originalName}</b>
                                            <button className="mt-1 button w-full bg-red-600" onClick={() => deleteAttachment(a.id)}>
                                                Elimina
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Layout>
    );
};
